import styled, { css } from 'styled-components';

export const labelStyle = css`
  line-height: ${({ theme }) => theme.font.lineHeight.label};
  font-family: ${({ theme }) => theme.font.family.bold};
  text-transform: uppercase;
  letter-spacing: ${({ theme }) => theme.font.letterSpacing.wide};
  background: transparent;
  color: ${({ theme }) => theme.color.text.secondary};
  font-size: ${({ theme }) => theme.font.size.label.secondary};
  transition: border-bottom-color
    ${({ theme }) => theme.effects.transition.quick};

  &:hover {
    border-bottom-color: ${({ theme }) => theme.color.elements.hover};
  }
`;

const Label = styled.p`
  ${labelStyle}
`;

export default Label;
