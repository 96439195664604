import styled from 'styled-components';

const Container = styled.div`
  max-width: 1280px;
  padding-left: 80px;
  padding-right: 80px;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.media.notebook`
    padding-left: 50px;
    padding-right: 50px;
  `}

  ${({ theme }) => theme.media.tablet`
    padding-left: 30px;
    padding-right: 30px;
  `}

  ${({ theme }) => theme.media.mobile`
    padding-left: 20px;
    padding-right: 20px;
  `}
`;

export default Container;
