import React from 'react';
import styled, { css } from 'styled-components';
import Container from '../components/Container';

import Caption from "../components/Caption";

const Section = styled.section`
  background: ${({ theme }) => theme.color.background.light};
  overflow: hidden;

  ${({ theme, secondary }) => secondary && css`
    background: ${theme.color.background.base};
    margin-bottom: 2rem;
  `}

  ${({ theme }) => theme.media.tablet`
    max-height: none;
    height: auto;
  `};
`;

const HeroContainer = styled(Container)`
  height: 100%;
  position: relative;
  padding-top: 10rem;
  padding-bottom: 6rem;

  ${({secondary}) => secondary && css`
    padding-bottom: 0;
  `}

  ${({ theme, secondary }) => theme.media.tablet`
    padding-top: 8rem; 
    padding-bottom: 6rem;
    text-align: center;

    ${secondary && css`
      text-align: left;
      padding-bottom: 0;
    `}
  `};

  ${({ theme }) => theme.media.mobile`
    text-align: left;
  `};

  ${Caption} {
    ${({ theme }) => theme.media.tablet`
      margin-left: auto;
      margin-right: auto;
    `} 
  }
`;

const Hero = props => {
  const { secondary } = props;

  return (
    <Section secondary={secondary}>
      <HeroContainer secondary={secondary}>
        {props.children}
      </HeroContainer>
    </Section>
  );
};

export default Hero;
