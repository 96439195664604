import { css } from 'styled-components';

const breakpoints = {
  notebook: 1360,
  tablet: 1040,
  mobile: 640,
};

const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media screen and (max-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});

const theme = {
  color: {
    accent: {
      primary: '#009bfe',
      secondary: '#ff0333',
    },
    background: {
      base: '#fff',
      light: '#f5f5f5',
      dark: '#000',
    },
    text: {
      primary: '#222',
      secondary: '#aaa',
      tertiary: '#fff',
      base: '#666',
    },
    elements: {
      border: '#ddd',
      hover: '#222',
      link: '#009bfe',
      icon: '#666',
      line: '#eee',
      menu: '#222',
      button: 'rgba(0, 0, 0, 0.1)',
      buttonHover: 'rgba(0, 0, 0, 0.2)',
    },
  },
  font: {
    family: {
      base: 'SilkaMedium, sans-serif',
      bold: 'SilkaSemibold, sans-serif',
    },
    size: {
      base: '16px',
      heading: {
        primary: '3rem',
        secondary: '1.875rem',
        tertiary: '1.5rem',
        base: '1rem',
      },
      label: {
        primary: '1.25rem',
        secondary: '0.875rem',
        tertiary: '0.75rem',
        base: '1rem',
      },
    },
    letterSpacing: {
      wide: '0.5rem',
    },
    lineHeight: {
      heading: '1.3',
      label: '1.2',
      caption: '1.5',
      paragraph: '1.8',
    },
  },
  size: {
    border: {
      base: '2px',
      thin: '1px',
    },
  },
  effects: {
    shadow: {
      box: '0 3px 15px 0 rgba(0, 0, 0, 0.06)',
      menu: '0 3px 15px 0 rgba(0, 0, 0, 0.06)',
    },
    transition: {
      none: '0s ease-out',
      quick: '0.2s ease-out',
      base: '0.4s ease-out',
      medium: '0.6s ease-out',
      slow: '1s cubic-bezier(0.89,-0.02, 0.38, 0.99)',
    },
  },
  media: Object.assign({}, media),
};

export default theme;
