import styled from 'styled-components';

const Heading = styled.h2`
  line-height: ${({ theme }) => theme.font.lineHeight.heading};
  font-family: ${({ theme }) => theme.font.family.bold};
  color: ${({ theme }) => theme.color.text.primary};
  font-size: ${({ theme }) => theme.font.size.heading.secondary};
`;

export default Heading;
