import styled from 'styled-components';
import simpleIcons from 'simple-icons';
import React, { Component } from 'react';

const IconWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  background: transparent;
  color: ${({ theme }) => theme.color.text.primary};
  padding: 0.5rem 1.2rem;
  width: 1.5rem;
  height: 1.5rem;
  transition: transform ${({ theme }) => theme.effects.transition.quick};

  &:hover {
    transform: translateY(-20%);

    svg {
      fill: ${({ theme }) => theme.color.elements.hover};
    }
  }

  svg {
    fill: ${({ theme }) => theme.color.elements.icon};
    transition: fill ${({ theme }) => theme.effects.transition.quick};
  }
`;

class Icon extends Component {
  createMarkup() {
    return { __html: simpleIcons[this.props.symbol].svg };
  }

  render(props) {
    return <IconWrapper dangerouslySetInnerHTML={this.createMarkup()} />;
  }
}

export default Icon;
