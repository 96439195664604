import styled from 'styled-components';

const CallToAction = styled.a`
  cursor: pointer;
  display: inline-block;
  background: ${({ theme }) => theme.color.elements.button};
  border-radius: 50px;
  line-height: ${({ theme }) => theme.font.lineHeight.label};
  font-family: ${({ theme }) => theme.font.family.bold};
  color: ${({ theme }) => theme.color.text.primary};
  font-size: ${({ theme }) => theme.font.size.label.secondary};
  padding: 0.6rem 1.8rem;
  transition: background-color ${({ theme }) => theme.effects.transition.base};

  &:hover {
    text-decoration: none;
    background: ${({ theme }) => theme.color.elements.buttonHover};
  }
`;

export default CallToAction;
