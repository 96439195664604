import styled from 'styled-components';

const Title = styled.h1`
  font-family: ${({ theme }) => theme.font.family.bold};
  font-size: ${({ theme }) => theme.font.size.heading.primary};
  margin-top: 1em;

  ${({ theme }) => theme.media.mobile`
    font-size: ${theme.font.size.heading.secondary};
  `};
`;

export default Title;
