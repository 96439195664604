import styled from 'styled-components';

const Caption = styled.p`
  font-size: ${({ theme }) => theme.font.size.label.primary};
  margin-bottom: 1em;
  color: ${({ theme }) => theme.color.text.primary};
  max-width: 32rem;

  ${({ theme }) => theme.media.mobile`
    font-size: ${theme.font.size.label.base};
    line-height: ${theme.font.lineHeight.paragraph};
    margin-left: 0;
    max-width: none;
  `};
`;

export default Caption;
