import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import Label from '../components/Label';
import Container from '../components/Container';

const ListWrapper = styled(Container)`
  position: relative;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 80px;

  ${({ theme }) => theme.media.notebook`
    padding-top: 80px;
    padding-bottom: 80px;
  `} ${({ theme }) => theme.media.mobile`
    padding-bottom: 60px;
  `};
`;

const ListLabel = styled(Label)`
  opacity: 0;
  transition: opacity ${({ theme }) => theme.effects.transition.base};
  transition-delay: ${({ isMenuOpened }) => (isMenuOpened ? 0.6 : 0)}s;

  ${({ isMenuOpened }) =>
    isMenuOpened &&
    css`
      opacity: 1;
    `}
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: block;
  margin-top: 1rem;
`;

const ListLink = styled.a`
  display: inline-block;
  text-decoration: none;
  transition: color ${({ theme }) => theme.effects.transition.quick};
  font-size: ${({ theme }) => theme.font.size.heading.secondary};
  color: ${({ theme }) => theme.color.text.base};
  margin: 0.5em 0 0;
  position: relative;

  &:hover {
    color: ${({ theme }) => theme.color.text.primary};
    text-decoration: none;
  }

  opacity: 0;
  transform: translateY(-50%);
  transition: opacity ${({ theme }) => theme.effects.transition.base},
    transform ${({ theme }) => theme.effects.transition.base};
  transition-delay: ${({ isMenuOpened, delay, initialDelay }) =>
    isMenuOpened ? initialDelay + delay : delay}s;

  ${({ isMenuOpened }) =>
    isMenuOpened &&
    css`
      opacity: 1;
      transform: translateY(0);
    `}
`;

const nav = [
  {
    label: `Home`,
    path: `/`,
  },
  {
    label: `About`,
    path: `/about`,
  },
  {
    label: `Blog`,
    href: `https://blog.tomaszgil.me`,
    target: '_blank',
  },
  {
    label: `Projects`,
    path: `/projects`,
  },
  {
    label: `Contact`,
    onClick: () =>
      window.scroll({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      }),
  },
];

const callAll = (...fns) => (...args) => fns.forEach(fn => fn && fn(...args));

const NavList = ({ onLinkClick, isMenuOpened }) => (
  <ListWrapper>
    <ListLabel isMenuOpened={isMenuOpened}>Menu</ListLabel>
    <List>
      {nav.map(({ label, path, onClick, ...props }, index) => (
        <li key={path}>
          <ListLink
            as={path ? Link : undefined}
            to={path}
            onClick={callAll(onLinkClick, onClick)}
            index={`0${index + 1}`}
            isMenuOpened={isMenuOpened}
            initialDelay={0.6}
            delay={index * 0.15}
            {...props}
          >
            {label}
          </ListLink>
        </li>
      ))}
    </List>
  </ListWrapper>
);

export default NavList;
