import React from 'react';
import styled, { css } from 'styled-components';
import Container from './Container';

const Wrapper = styled.section`
  margin: 100px 0;

  ${({ first }) =>
    first &&
    css`
      margin-top: 50px;
    `}

  ${({ secondary, theme }) =>
    secondary &&
    css`
      background: ${theme.color.background.light};
    `}

  ${({ theme, first }) => theme.media.tablet`
    margin: 80px 0;

    ${first && css`
      margin-top: 30px;
    `}
  `}

  ${({ last }) =>
    last &&
    css`
      & {
        margin-bottom: 0;
      }
    `}
`;

const Content = styled.div`
  ${({ theme, separator }) =>
    separator &&
    css`
      border-bottom: ${theme.size.border.thin} solid
        ${theme.color.elements.line};
      padding-bottom: 100px;

      ${theme.media.tablet`
      padding-bottom: 80px;
    `};
    `} ${({ theme, secondary }) =>
    secondary &&
    css`
      padding: 100px 0;

      ${theme.media.tablet`
      padding: 80px 0;
    `};
    `};
`;

const Section = props => {
  const { secondary, separator, last, first, children } = props;

  return (
    <Wrapper secondary={secondary} last={last} first={first}>
      <Container>
        <Content separator={separator} secondary={secondary}>
          {children}
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Section;
