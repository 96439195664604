import styledNormalize from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';

// Font imports
import SilkaMediumEot from './fonts/silka-medium-webfont.eot';
import SilkaMediumWoff from './fonts/silka-medium-webfont.woff';
import SilkaMediumWoff2 from './fonts/silka-medium-webfont.woff2';
import SilkaMediumTtf from './fonts/silka-medium-webfont.ttf';
import SilkaSemiboldEot from './fonts/silka-semibold-webfont.eot';
import SilkaSemiboldWoff from './fonts/silka-semibold-webfont.woff';
import SilkaSemiboldWoff2 from './fonts/silka-semibold-webfont.woff2';
import SilkaSemiboldTtf from './fonts/silka-semibold-webfont.ttf';

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  @font-face {
    font-family: 'SilkaMedium';
    src: url('${SilkaMediumEot}');
    src: url('${SilkaMediumEot}?#iefix') format('embedded-opentype'),
         url('${SilkaMediumWoff2}') format('woff2'),
         url('${SilkaMediumWoff}') format('woff'),
         url('${SilkaMediumTtf}') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'SilkaSemibold';
    src: url('${SilkaSemiboldEot}');
    src: url('${SilkaSemiboldEot}?#iefix') format('embedded-opentype'),
         url('${SilkaSemiboldWoff2}') format('woff2'),
         url('${SilkaSemiboldWoff}') format('woff'),
         url('${SilkaSemiboldTtf}') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  :root {
    font-size: ${({ theme }) => theme.font.size.root};
  }

  body {
    color: ${({ theme }) => theme.color.text.base};
    font-family: ${({ theme }) => theme.font.family.base};
    margin: 0;
    overflow-x: hidden;  
  }

  main {
    background: ${({ theme }) => theme.color.background.base};
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.color.text.primary};
    line-height: ${({ theme }) => theme.font.lineHeight.heading}
  }

  h1 {
    font-size: ${({ theme }) => theme.font.size.heading.primary};
  }

  h2 {
    font-size: ${({ theme }) => theme.font.size.heading.secondary};
  }

  h3 {
    font-size: ${({ theme }) => theme.font.size.heading.tertiary};
  }

  p {
    line-height: ${({ theme }) => theme.font.lineHeight.paragraph}; 
    color: ${({ theme }) => theme.color.text.base};
    margin: 1em 0 1.5em;
  }

  ul {
    margin: 1em 0;
    padding: 0;
    list-style-type: none;
  }

  li {
    line-height: ${({ theme }) => theme.font.lineHeight.paragraph}; 
    color: ${({ theme }) => theme.color.text.base};
    margin-bottom: 1em;
  }

  a {
    color: ${({ theme }) => theme.color.elements.link};
    cursor: pointer;
    text-decoration: none;
    line-height: inherit;

    &:hover {
      text-decoration: underline;
    }
  }

  button {
    cursor: pointer;
    background: none;
    outline: 0;
    border: 0;
  }

  img {
    height: auto;
    max-width: 100%;
  }
`;

export default GlobalStyle;
