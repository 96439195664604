import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import { ThemeProvider } from 'styled-components';
import theme from '../styles/theme';
import GlobalStyle from '../styles/GlobalStyle';
import thumbnail from '../img/thumbnail.png';

const TemplateWrapper = ({ children }) => (
  <Fragment>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <meta charSet="utf-8" />
      <title>Tomasz Gil - software engineer</title>
      <meta
        property="description"
        content="My name is Tomasz Gil. I’m a software engineer focused on problem solving, lifelong learner based in Poznań, Poland."
      />
      <meta property="og:title" content="Tomasz Gil - software engineer" />
      <meta
        property="og:description"
        content="My name is Tomasz Gil. I’m a software engineer focused on problem solving, lifelong learner based in Poznań, Poland."
      />
      <meta property="og:image" content={thumbnail} />
      <meta property="og:url" content="http://tomaszgil.me" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    </Helmet>
    <ThemeProvider theme={theme}>
      <Fragment>
        <Header />
        <main>{children}</main>
        <Footer />
        <GlobalStyle />
      </Fragment>
    </ThemeProvider>
  </Fragment>
);

export default TemplateWrapper;
