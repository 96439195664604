import React, { Component } from 'react';
import { Link } from 'gatsby';
import logo from '../img/logo.svg';
import styled, { css } from 'styled-components';
import Container from '../components/Container';
import CallToAction from '../components/CallToAction';
import NavList from './NavList';

const StyledHeader = styled.header`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 2rem 0;

  ${({ theme }) => theme.media.tablet`
    padding: 1.5rem 0;
  `} ${({ theme }) => theme.media.mobile`
    padding: 1rem 0;
  `};
`;

const Wrapper = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoLink = styled(Link)`
  position: relative;
  top: 4px;
  z-index: 200;
  display: inline-block;

  img {
    width: 130px;

    ${({ theme }) => theme.media.mobile`
      width: 110px;
    `};
  }
`;

const Nav = styled.nav`
  box-sizing: border-box;
  background: ${props => props.theme.color.background.base};
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  z-index: 200;
  transition: transform ${props => props.theme.effects.transition.medium};

  ${props =>
    props.isOpened &&
    css`
      transform: translateY(0);
      transition: transform ${props => props.theme.effects.transition.slow};
    `}
`;

const NavOverlay = styled.div`
  background: ${({ theme }) => theme.color.background.dark};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0;
  transition: opacity ${({ theme }) => theme.effects.transition.base},
    visibility ${({ theme }) => theme.effects.transition.none} 1s;
  visibility: hidden;
  z-index: 100;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 0.2;
      visibility: visible;
      transition: opacity ${({ theme }) => theme.effects.transition.slow},
        visibility ${({ theme }) => theme.effects.transition.none} 0s;
    `};
`;

const ContactButtonWrapper = styled.div`
  position: relative;
  z-index: 100;
  margin-right: 60px;

  ${({ theme }) => theme.media.mobile`
    display: none;
  `};
`;

const Floating = styled.div`
  position: fixed;
  width: 100%;
  z-index: 300;
  left: 0;
  pointer-events: none;
`;

const Hamburger = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  height: 40px;
  margin-left: auto;
  outline: 0;
  right: 0;
  padding: 0.5rem;
  width: 40px;
  position: relative;
  pointer-events: all;

  &:focus,
  &:active {
    outline: 0;
  }

  span,
  span::before,
  span::after {
    background: ${({ theme }) => theme.color.elements.menu};
    content: '';
    position: relative;
    display: block;
    height: 2px;
    transition: transform ${({ theme }) => theme.effects.transition.quick},
      background-color ${({ theme }) => theme.effects.transition.quick} 0.2s,
      top ${({ theme }) => theme.effects.transition.quick} ${1.5 * 0.2}s;
    width: 30px;
  }

  span::before {
    top: 8px;
  }

  span::after {
    top: -10px;
  }

  ${({ theme, isOpened }) =>
    isOpened &&
    css`
      span,
      span::before,
      span::after {
        transition: top ${theme.effects.transition.quick}, background-color 0s ease 0.2s,
          transform ${theme.effects.transition.quick} ${1.5 * 0.2}s;
      }

      span {
        background: transparent;

        &::before {
          top: 0;
          transform: rotate(45deg);
        }

        &::after {
          top: -2px;
          transform: rotate(-45deg);
        }
      }
    `};
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.menuOpenedClass = 'is-menu-opened';

    this.state = {
      menuOpened: false,
    };

    this.handleMobileMenu = this.handleMobileMenu.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.handleEscPress = this.handleEscPress.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleEscPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscPress);
  }

  handleEscPress = e => {
    console.log(e);
    if (this.state.menuOpened && e.key === 'Escape') {
      this.handleMobileMenu();
    }
  };

  handleMobileMenu() {
    this.setState(
      {
        menuOpened: !this.state.menuOpened,
      },
      () => {
        if (this.state.menuOpened) {
          this.disableScroll();
        } else {
          this.restoreScroll();
        }
      }
    );
  }

  disableScroll() {
    document.body.style.overflow = 'hidden';
  }

  restoreScroll() {
    document.body.style.overflow = 'auto';
  }

  scrollToBottom() {
    if (this.state.menuOpened) this.handleMobileMenu();

    window.scroll({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  }

  render() {
    return (
      <StyledHeader>
        <Wrapper>
          <LogoLink to="/">
            <img src={logo} alt="Tomasz Gil" />
          </LogoLink>
          <NavOverlay onClick={this.handleMobileMenu} isVisible={this.state.menuOpened} />
          <Nav isOpened={this.state.menuOpened} id="menu">
            <NavList onLinkClick={this.handleMobileMenu} isMenuOpened={this.state.menuOpened} />
          </Nav>
          <ContactButtonWrapper>
            <CallToAction onClick={this.scrollToBottom}>Contact</CallToAction>
          </ContactButtonWrapper>
          <Floating>
            <Wrapper>
              <Hamburger
                role="button"
                onClick={this.handleMobileMenu}
                isOpened={this.state.menuOpened}
                aria-controls="menu"
                aria-label="Menu"
                aria-expanded={this.state.menuOpened}
              >
                <span />
              </Hamburger>
            </Wrapper>
          </Floating>
        </Wrapper>
      </StyledHeader>
    );
  }
}

export default Header;
