import styled, { css } from 'styled-components';

const Button = styled.a`
  cursor: pointer;
  display: inline-block;
  line-height: ${({ theme }) => theme.font.lineHeight.caption};
  font-family: ${({ theme }) => theme.font.family.bold};
  text-transform: uppercase;
  letter-spacing: ${({ theme }) => theme.font.letterSpacing.wide};
  background: transparent;
  border-bottom: ${({ theme }) => theme.size.border.base} solid
    ${({ theme }) => theme.color.elements.border};
  color: ${({ theme }) => theme.color.text.primary};
  font-size: ${({ theme }) => theme.font.size.label.secondary};
  padding: 0.5rem 0;
  transition: border-bottom-color ${({ theme }) => theme.effects.transition.quick};

  &:hover {
    text-decoration: none;
    border-bottom-color: ${({ theme }) => theme.color.elements.hover};
  }

  ${({ theme, active }) =>
    active &&
    css`
      border-bottom-color: ${theme.color.elements.hover};
    `}
`;

export default Button;
