import React, { Fragment } from 'react';
import logo from '../img/logo.svg';
import Icon from '../components/Icon';
import Container from '../components/Container';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Section from '../components/Section';
import Button from '../components/Button';
import Label from '../components/Label';
import Heading from '../components/Heading';
import Caption from '../components/Caption';

const FooterWrapper = styled.footer`
  padding: 80px 0;

  ${({ theme }) => theme.media.mobile`
    padding: 50px 0 60px;
  `};
`;

const FooterContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.media.mobile`
    flex-direction: column-reverse;
  `};
`;

const Branding = styled.div`
  ${({ theme }) => theme.media.mobile`
    margin-top: 2rem;
  `} a {
    display: inline-block;
  }

  img {
    width: 110px;

    ${({ theme }) => theme.media.mobile`
      width: 110px;
    `};
  }
`;

const SectionSubtitle = styled(Heading)`
  margin-bottom: 0;
`;

const SectionCaption = styled(Caption)`
  margin: 0.5rem 0 3rem;

  ${({ theme }) => theme.media.tablet`
    margin-left: 0;
    margin-right: 0;
  `}
`;

const Footer = () => (
  <Fragment>
    <Section secondary last>
      <Label as="h2">Contact me</Label>
      <SectionSubtitle as="p">Don't be a stranger, say hello!</SectionSubtitle>
      <SectionCaption>Feel free to reach out via email or on social media.</SectionCaption>
      <Button href={'mailto:tomaszgil.dev@gmail.com'}>Say hello</Button>
    </Section>
    <FooterWrapper>
      <FooterContainer>
        <Branding>
          <Link to="/">
            <img src={logo} alt="Tomasz Gil" />
          </Link>
        </Branding>
        <div>
          <a href="https://dribbble.com/tomaszgil" target="_blank" rel="noopener noreferrer">
            <Icon symbol="Dribbble" />
          </a>
          <a href="https://www.instagram.com/tomasz.gil/" target="_blank" rel="noopener noreferrer">
            <Icon symbol="Instagram" />
          </a>
          <a href="https://github.com/tomaszgil" target="_blank" rel="noopener noreferrer">
            <Icon symbol="GitHub" />
          </a>
          <a href="https://twitter.com/gil_tomasz" target="_blank" rel="noopener noreferrer">
            <Icon symbol="Twitter" />
          </a>
          <a href="https://blog.tomaszgil.me" target="_blank" rel="noopener noreferrer">
            <Icon symbol="Hashnode" />
          </a>
          <a
            href="https://www.linkedin.com/in/tomasz-gil/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon symbol="LinkedIn" />
          </a>
        </div>
      </FooterContainer>
    </FooterWrapper>
  </Fragment>
);

export default Footer;
